import {motion} from "framer-motion";
import {Link} from "@inertiajs/react";
import ApplicationLogo from "@/components/ApplicationLogo";
import {Button, createTheme, List, ListItem, ListItemIcon, ListItemText, styled} from "@mui/material";
import {CancelOutlined, CheckSharp} from "@mui/icons-material";


const animation = {
    hidden: {
        y: '100vh',
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: 'spring',
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: '100vh',
        opacity: 0,
        transition: {
            duration: 2.5,
            type: 'spring',
            damping: 25,
            stiffness: 500,
        }
    }
};

export default function SignUp({handleClose}: { handleClose: () => void }) {
    const LItem = styled('li')({
        listStyleImage: `url(${CheckSharp})`
    })

    return (
        <motion.div className="w-screen absolute top-0 right-0 bottom-0 left-0 overflow-hidden bg-bonza-dark"
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    key="signup"
        >

            <nav>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="shrink-0 flex items-center">
                                <Link href="/">
                                    <ApplicationLogo className="float-left h-9 w-auto fill-current"/>
                                    <p className="float-left lowercase pl-2 font-bold text-3xl -mt-1 text-gray-800 dark:text-gray-200 pt-1">Bonza</p>
                                </Link>
                            </div>
                        </div>
                        <div className="hidden sm:flex sm:items-center sm:ms-6 mt-2">
                            <Button onClick={() => handleClose()}>
                                <CancelOutlined className="text-white"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="flex flex-col h-full max-w-7xl mx-auto " style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <div>
                    <h1 className="text-3xl text-white">Choose your plan</h1>
                </div>


                <div className="grid grid-cols-3 gap-6 md:px-6 md:mx-6 text-white h-2/3" style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className="relative p-10 h-4/6 border border-gray-600 rounded-3xl">
                        <h2 className="text-xl pb-3 -mt-1">Free 2 week trial</h2>
                        <p className="text-sm pb-3">Now is the time for all good men to come to the aid of the
                            party.</p>
                        <ul className="text-sm">
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> First</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Second</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Third</li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div className="w-60 mb-4 pt-3 border-t border-gray-600">
                                <p><span className="text-3xl font-semibold">£0</span><span
                                    className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">try bonza for free</p>
                                <button className="w-full mt-3 bg-bonza-lilac text-bonza-semi-dark p-2 rounded-3xl">Sign
                                    up
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative p-10 h-4/6 bg-bonza-violet border border-bonza-violet rounded-3xl">
                        <h2 className="text-xl pb-3 -mt-1">Individual plan</h2>
                        <p className="text-sm pb-3">Now is the time for all good men to come to the aid of the
                            party.</p>
                        <ul className="text-sm">
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> First</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Second</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Third</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Fourth</li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div className="w-60 mb-4 border-t border-bonza-lilac">
                                <p><span className="text-3xl font-semibold">£25</span><span
                                    className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">per person, cancel anytime</p>
                                <button className="w-full mt-3 bg-bonza-lilac text-bonza-semi-dark p-2 rounded-3xl">Sign
                                    up
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative p-10 h-4/6 bg-bonza-semi-dark border border-bonza-semi-dark rounded-3xl">
                        <h2 className="text-xl pb-3 -mt-1">Group plan</h2>
                        <p className="text-sm pb-3">Now is the time for all good men to come to the aid of the
                            party.</p>
                        <ul className="text-sm">
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> First</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Second</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Third</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Fourth</li>
                            <li><CheckSharp className="text-xs text-bonza-lilac"/> Fifth</li>
                        </ul>
                        <div className="absolute bottom-0 m-3 pb-3">
                            <div className="w-60 mb-4 pt-3 border-t border-gray-600">
                                <p><span className="text-3xl font-semibold">£19</span><span
                                    className="text-sm">/month</span>
                                </p>
                                <p className="text-sm">per person, cancel anytime</p>
                                <button className="w-full mt-3 bg-bonza-lilac text-bonza-semi-dark p-2 rounded-3xl">Sign
                                    up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="absolute top-5 right-5"><a onClick={() => handleClose()}>Close</a></div>
            </div>
        </motion.div>
    );
}
